import React from 'react';

const blueMarketplace = '#329CC7';

// This is Facebook's logo, you are not allowed to change its color
export const Pause = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 500.000000 500.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill={blueMarketplace}
      stroke="none"
    >
      <path
        d="M2375 4900 c-446 -38 -864 -196 -1223 -464 -137 -102 -366 -331 -468
  -468 -693 -930 -613 -2206 191 -3037 451 -466 1038 -714 1685 -714 270 0 493
  35 730 113 360 119 652 301 926 574 311 312 511 661 618 1081 93 361 93 788 0
  1150 -198 779 -778 1401 -1542 1654 -282 93 -630 135 -917 111z m-105 -1300
  c24 -14 58 -45 75 -68 l30 -44 0 -927 0 -927 -27 -42 c-15 -22 -49 -54 -75
  -69 l-48 -28 -236 0 -236 0 -48 30 c-36 22 -57 45 -77 84 l-28 53 0 897 0 897
  26 53 c28 56 79 97 136 112 18 4 130 7 248 6 209 -2 216 -3 260 -27z m1145 -5
  c36 -22 57 -45 77 -84 l28 -53 0 -898 0 -898 -28 -53 c-20 -39 -41 -62 -77
  -84 l-48 -30 -236 0 -236 0 -48 28 c-26 15 -60 47 -75 69 l-27 42 -3 916 -2
  916 23 45 c26 49 86 96 138 109 19 5 131 8 250 7 l216 -2 48 -30z"
      />
    </g>
  </svg>
);

// This is Google's logo, you are not allowed to change its color
export const Play = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 500.000000 500.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill={blueMarketplace}
      stroke="none"
    >
      <path
        d="M2375 4900 c-446 -38 -864 -196 -1223 -464 -137 -102 -366 -331 -468
-468 -693 -930 -613 -2206 191 -3037 451 -466 1038 -714 1685 -714 270 0 493
35 730 113 360 119 652 301 926 574 311 312 511 661 618 1081 93 361 93 788 0
1150 -198 779 -778 1401 -1542 1654 -282 93 -630 135 -917 111z m-191 -1189
c85 -37 1512 -866 1548 -899 21 -19 52 -64 70 -100 29 -59 33 -76 33 -152 0
-76 -4 -93 -33 -152 -18 -36 -49 -81 -70 -100 -36 -32 -1459 -860 -1551 -901
-34 -16 -67 -21 -126 -22 -71 0 -88 4 -147 33 -81 40 -149 111 -177 188 -21
54 -21 71 -21 954 0 880 0 900 20 954 37 98 132 183 239 213 59 17 159 9 215
-16z"
      />
    </g>
  </svg>
);
